// CardsProduct.js
import React, { useState, useEffect } from 'react';
import CardProduct from './CardProduct';
import TextH11 from "../Fonts/H11.js";
import TextH1 from "../Fonts/H1.js";
import CalculateItem from './CalculateItem.js';


export default function CardsProduct() {
    const [products, setProducts] = useState([]);
    const [cartList, setCartList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/products');
                const data = await response.json();

                let result = {}
                data.forEach((e) => {
                    if (result[e.type] == undefined) {
                        result[e.type] = []
                    }
                    result[e.type].push(e)
                })

                setProducts(result);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchData();

    }, []);

    const addToCart = (item) => {
        const existingItem = cartList.find(cartItem => cartItem.title === item.title);
        if (existingItem) {
            const updatedCartList = cartList.map(cartItem =>
                cartItem.id === existingItem.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
            );
            setCartList(updatedCartList);
        } else {
            setCartList([...cartList, { ...item, id: cartList.length + 1, quantity: 1 }]);
        }
    };
    return (
        <div style={{ padding: '0 10%', marginBottom: '20px' }}>
            <div>
                <div style={{ padding: '10px' }}>
                    {Object.keys(products).map((typeName) => 
                        <div key={typeName}>
                            <div style={{marginTop:'80px', marginBottom:'40px'}} id={typeName}>
                                <TextH11 text={typeName}></TextH11>
                            </div>
                            <div  style={{ overflowX: 'auto', whiteSpace: 'nowrap', borderRadius: '10px', padding: '10px', scrollbarWidth: 'auto', scrollbarColor: '#98A446 #EBE8E3', '::-webkit-scrollbar': { width: '8px' }, '::-webkit-scrollbar-thumb': { borderRadius: '10px', backgroundColor: '#98A446' } }}>
                                {products[typeName].map((item, index) => 
                                    <div key={index} style={{ display: 'inline-block', marginRight: '20px' }}>
                                        <CardProduct
                                            title={item.title}
                                            description={item.description}
                                            volume={item.volume}
                                            image={item.image}
                                            price={item.price}
                                            type={item.type}
                                            package_number={item.package_number}
                                            package_type={item.package_type}
                                            package_size={item.package_size}
                                            package_volume={item.package_volume}
                                            addToCart={addToCart} 
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div style={{marginTop:'80px'}}>
            <TextH11 text = "Скидки"/>
            </div>
            <div className='skidki' >
                    <div className='skidki_pozicii'>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <TextH1 text = "-1% При заказе от 3-х позиций"/>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <TextH1 text = "-2% При заказе от 5-х позиций"/>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <TextH1 text = "-3% При заказе от 8-х позиций"/>
                            </div>
                    </div>

                    <div className='skidki_stoimost'>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <TextH1 text = "-3% При заказе от 50.000 руб"/>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <TextH1 text = "-5% При заказе от 200.000 руб"/>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <TextH1 text = "-7% При заказе от 1.000.000 руб"/>
                            </div>
                    </div>
                

            </div>
            <div style={{marginTop:'80px'}}>
                    <TextH11 text = "Калькулятор стоимости товара с учетом оптовых скидок"/>
                    <CalculateItem cartList={cartList} setCartList={setCartList} />

            </div>
        </div>
    );
}
