import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

const CircularIndeterminate = ({ color = 'primary' }) => {
  return (
    <Backdrop
      sx={{ zIndex: 9999, color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      open={true}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
};

export default CircularIndeterminate;
