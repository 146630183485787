import React from 'react';
import Bodyyy from "../Fonts/textBody";
import '../Components_Home/basement.css'; 

function Basement() {
    return (
        <div className='podval'>
            <div className='base'>
                <div className="logo">
                    {/* Ваш логотип */}
                    <img src="img\logo.PNG" alt="Логотип" />
                    <span>Богатство Шербакуля</span> {/* Богатство Шербакуля */}
                </div>
                <div className='text'>
                    <div className='Phones'>
                        <Bodyyy text="Телефон бухгалтерии:"/>
                        <Bodyyy text="+7-953-393-09-87"/>
                    </div>
                    <div className='Phones'>
                        <Bodyyy text="Телефон менеджера по продажам:"/>
                        <Bodyyy text="+7-950-794-64-25"/>
                    </div>
                    <Bodyyy text="646700, Россия, Омская область, Шербакульский район, рп. Шербакуль, ул. Совестская, д. 30"/>
                    <Bodyyy text="©2024 (Богатство Шербакуля). Название бренда и логотип - собственность ИП Глава КФХ Шилова С.Н."/>
                </div>
             </div>
        </div>
        
    );
  }
  
  export default Basement;