import React, { useMemo, useState, useEffect } from 'react';
import './Product.css';
import { useTable } from 'react-table';
import AddButton from './addButton.js';
import RemoveButton from './removeButton.js';

function CalculateItem({ cartList, setCartList }) {
    const [data, setData] = useState([]);

    
    useEffect(() => {
        setData(cartList);
    }, [cartList]);

    
    const columns = useMemo(() => [
        {
            Header: '№',
            accessor: 'id',
        },
        {
            Header: 'Наименование товара',
            accessor: 'nameItem',
        },
        {
            Header: 'Стоимость за единицу товара',
            accessor: 'PricePerItem',
        },
        {
            Header: 'Тип упаковки',
            accessor: 'TypePackage',
        },
        {
            Header: 'Количество товара в 1 упаковке',
            accessor: 'QuantityPerItem',
        },
        {
            Header: 'Количество упаковок',
            accessor: 'quantity',
        },
        {
            Header: 'Итоговая стоимость',
            accessor: 'TotalPricePerItem',
        },
    ], []);

    const tableData = useMemo(() => {
        const updateQuantity = (index, newQuantity) => {
            const newData = [...data];
            newData[index].quantity = newQuantity;
            setData(newData);
            console.log('Updated data:', newData); // Проверка обновленных данных
        
            // Обновление cartList
            const updatedCartList = newData.filter(item => item.quantity > 0);
            setCartList(updatedCartList);
        };
        
        const addQuantityPerItem = (itemId) => {
            const index = itemId; // Номер строки начинается с 1, поэтому используем прямо itemId
            if (index !== -1) {
                const currentQuantity = data[index].quantity;
                const newQuantity = currentQuantity + 1;
                updateQuantity(index, newQuantity);
            }
        };    
        
        const removeQuantityPerItem = (itemId) => {
            const index = itemId; // Номер строки начинается с 1, поэтому используем прямо itemId
            if (index !== -1) {
                const currentQuantity = data[index].quantity;
                const newQuantity = Math.max(0, currentQuantity - 1);
                updateQuantity(index, newQuantity);
            }
        };
    
        const filteredCartList = cartList.filter(item => item.quantity > 0);
        return filteredCartList.map((item, index) => {
            return {
                id: index + 1,
                nameItem: item.title,
                PricePerItem: `${item.price} руб`,
                TypePackage: item.package_type,
                QuantityPerItem: item.package_number,
                quantity: (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent:'center' }}>
                        <RemoveButton onClick={() => removeQuantityPerItem(index)} />
                        {item.quantity}
                        <AddButton onClick={() => addQuantityPerItem(index)}></AddButton>
                    </div>
                ),
                TotalPricePerItem: `${item.price * item.package_number * item.quantity} руб`
            };
        });
    }, [cartList, data, setCartList]);
    
    
    const tableInstance = useTable({
        columns,
        data: tableData
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    const totalCost = useMemo(() => {
        let total = 0;
        tableData.forEach(item => {
            total += parseFloat(item.TotalPricePerItem.replace(' руб', ''));
        });
    
        // Применение скидки в зависимости от общей стоимости
        let discountedTotal = total;
        if (total >= 1000000) {
            discountedTotal *= (1 - 0.07);
        } else if (total >= 200000) {
            discountedTotal *= (1 - 0.05);
        } else if (total >= 50000) {
            discountedTotal *= (1 - 0.03);
        }
    
        // Вычисление общего количества уникальных видов товаров
        const uniqueItemsCount = new Set(tableData.map(item => item.nameItem)).size;
    
        // Применение дополнительных скидок в зависимости от количества видов товаров в таблице
        if (uniqueItemsCount >= 8) {
            discountedTotal *= (1 - 0.03);
        } else if (uniqueItemsCount >= 5) {
            discountedTotal *= (1 - 0.02);
        } else if (uniqueItemsCount >= 3) {
            discountedTotal *= (1 - 0.01);
        }
    
        return discountedTotal.toFixed(2); // Округляем до двух знаков после запятой
    }, [tableData]);

    return (
        <div className="CartTableContainer"  style={{ marginTop: '40px' }}>
            <table {...getTableProps()} className='CartTable'>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()} className="total-price-column"
                                    style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        
                                    }}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} style={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: 'initial' }}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td 
                                            {...cell.getCellProps()} 
                                            className={cell.column.id === 'TotalPricePerItem' ? 'total-price-column' : null} // Добавляем класс только для колонки 'Итоговая стоимость'
                                            style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '15px',
                                                fontWeight: '320',
                                                
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {/* Вывод общей стоимости под таблицей */}
            <div style={{fontFamily: 'Open Sans', marginTop: '20px', textAlign: 'left', paddingLeft: '20px', paddingBottom:'20px', fontSize: '16px', fontWeight: '400' }}>
                Общая стоимость всех товаров (Без учета НДС):
                <span style={{ whiteSpace: 'nowrap' }}> {totalCost} руб</span>
            </div>
        </div>
    );
}

export default CalculateItem;


