import React from 'react';
import Header from '../Components_Home/Header';
import TextH111 from "../Fonts/H111.js";
import TextH11 from "../Fonts/H11.js";
import TextH1 from "../Fonts/H1.js";
import Bodyyy from "../Fonts/textBody.js";
import Bodyyy2 from "../Fonts/textBodyH.js";
import Basement from '../Components_Home/basement.js';
import './Services.css';
import { Helmet } from 'react-helmet';

function ServicesPage() {
    return (
      <div style={{ margin: 0, padding: 0 }}>

        <Helmet>
          <title>Богатство Шербакуля - оказываемые усулги</title>
          <meta name="description" content="Услуги подработки семян в Омской области от КФХ Богатство Шербакуля: сушка и хранение семян, очистка от примесей зерновых и масличных культур дешёво." />
          <meta name="keywords" content="услуги подработки семян, сушка семян, хранение семян, очистка семян от примесей, очистка зерновых культур, очистка масличных культур, КФХ Богатство Шербакуля, подработка семян в Омской области, сушка и хранение семян, дешёвая очистка семян, услуги сушки семян, услуги хранения семян, очистка семян в Омске, услуги подработки зерновых, услуги подработки масличных культур" />
        </Helmet>

        <Header />
        <main style={{position: 'relative', width: 'auto', height:'100%',  overflowX: 'hidden'}}>
            <img className="mainPicHeader" src="/img/main8.jpeg" alt="" />
            <div className='SHIK'>
            <div style={{width:'96%', margin:'0px auto'}}>
                <TextH111 text="Богатство Шербакуля"/>
                <TextH1 text="Семена и масла от фермерского хозяйства"/>
                <TextH1 text="Из сердца Сибири с заботой о Вас"/>
            </div>
            </div>
        </main>  

        <div className='ContainerAboutUsPageAboutUs1'>
          <div className='AboutUsSvg'>
            <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/af4bd0ba931515d81d64ccdeb288b8b15e8feccda4874dc9cd32e1c9a38e58c9?apiKey=e49b7d88eb524bfa990aed2fcde09947&"
            alt="Company logo"
            className="aspect-square w-[50px]"/>
          </div>

             <div className='TextAboutUs1'>
                <TextH11 text="Оказываемые услуги"/>
                <Bodyyy text="Фермерское хозяйство ''Богатство Шербакуля'' оказывает услуги по подработке, хранению, переработке сырья. Свяжитесь с нашим специалистом если хотите узнать больше."/>
            </div>
        </div>


    <div className='MainContainer1'>
        <div className='ServicesBlock1' style={{marginTop:'80px'}}>
            <div className='picture1'>
                <img src="img/services1.jpg" alt="Картинка" style={{padding:'30px'}}/>
            </div>
            <div className='Text1'>
                <div style={{marginBottom:'32px'}}>
                    <TextH1 text="Очистка семян на Петкус-К527 и К-518"/>
                </div>
                <div style={{textAlign:'center'}}>
                    <Bodyyy2 text="Первичная очистка семян:"/>
                </div>
                <Bodyyy text="Предназначена для предварительной и первичной очистки поступающего с поля зернового вороха колосовых, крупяных, зернобобовых культур, кукурузы, сорго, подсолнечника от примесей во всех зонах РФ и стран СНГ." withParagraph={true}/>
            </div>
       </div>

       <div className='ServicesBlock2' style={{marginTop:'80px'}}>
            <div className='Text2'>
                <div style={{marginBottom:'32px'}}>
                    <TextH1 text="Очистка семян на фотосепараторе ''Зоркий''"/>
                </div>
                <div style={{textAlign:'center'}}>
                    <Bodyyy2 text="Глубокая очистка семян до 99,9% чистоты:" />
                </div>
                    <Bodyyy text="Очистка на фотосепараторе предназначена для доведения семян до чистоты 99,9% - пищевого показателя. Производится по цветовому признаку, подробности уточняйте у специалиста." withParagraph={true}/>
            </div>
            <div className='picture2'>
                <img src="img/services2.png" alt="Картинка"/>
            </div>
       </div>
     </div>

     <div className='MainContainer1'>
        <div className='ServicesBlock1' style={{marginTop:'80px'}}>
            <div className='picture1'>
                <img src="img/services3.jpg" alt="Картинка"/>
            </div>
            <div className='Text1'>
                <div style={{marginBottom:'32px'}}>
                    <TextH1 text="Складские услуги"/>
                </div>
                <div style={{textAlign:'center'}}>
                    <Bodyyy2 text="Круглогодичный крытый склад с охраной и видеонаблюдением"/>
                </div>
                <Bodyyy text="Оказываем складские услуги под любые нужды. Крытый асфальтированный склад до 5000 тонн семян. Есть условия для техники и других товаров. Охрана, видеонаблюдение, забор и собаки - сохранность как в банке." withParagraph={true}/>
            </div>
       </div>

       <div className='ServicesBlock2' style={{marginTop:'80px'}}>
            <div className='Text2'>
                <div style={{marginBottom:'32px'}}>
                    <TextH1 text="Сушка на итальянской зерносушилке AGRIMEC"/>
                </div>
                <div style={{textAlign:'center'}}>
                    <Bodyyy2 text="Для влажного сырья"/>
                </div>
                    <Bodyyy text="Сушка зерна и масличных от 20% влаги и выше на итальянском оборудовании. Доведем до кондиции Ваше сырье." withParagraph={true}/>
            </div>
            <div className='picture2'>
                <img src="img/services4.png" alt="Картинка"/>
            </div>
       </div>
     </div>

 

        <Basement/>
        </div>
    );
  }

  export default ServicesPage