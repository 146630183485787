import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Button from '../buttom';
import TextH11 from "../Fonts/H11";
import TextH1 from "../Fonts/H1";
import Product from '../Components_Products/Product';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
</style>



const SingleCard = ({ title, image, id }) => {
  return (
    // <Card>
      <div className='CardAssortiment' style={{borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'}}>
      <CardMedia
        component="img"
        alt={title}
        height="200"
        image={image}
      />
      <div className="TBAssortiment">
        <TextH1 text={title}/>
        <Link to={{ pathname: 'Product', hash: '#top' }}>
          <Button>Перейти</Button>
        </Link>
        
      </div>
    </div>
  );
};

export default function Assortiments() {
  // Массив данных о карточках
  const cardsData = [
    { title: 'Масла', image: 'img/oils.png', id: 'Масла растительные' },
    { title: 'Семена', image: 'img/seeds.png', id: 'Фасованные очищенные семена' },
    { title: 'Жмых', image: 'img/zhmih.png', id: 'Жмых' }
  ];

  return (
    <div className='cardsAssortiment'>
        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center', marginTop: '40px' }} */}
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
          {/* Создаем карточки в цикле */}
          {cardsData.map((card, index) => (
            <SingleCard key={index} title={card.title} image={card.image} id={card.id}/>
          ))}
        {/* </div> */}
    </div>
  );
}