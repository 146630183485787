import React, { useState, useEffect, useRef } from 'react';
import MenuIcon from '@mui/icons-material/Menu';


function Header() {
  const [isOpen, setOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header>
      <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={() => setOpen(false)}></div>
      
      <nav className="navbar" ref={navRef}>
        <div className="logoH">
          <img src="img\logo.PNG" alt="Логотип" />
          <span>Богатство Шербакуля</span>
        </div>
          <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
            <li><a href="/">Главная</a></li>
            <li><a href="/Product">Продукция</a></li>
            <li><a href="/ServicesPage">Услуги</a></li>
            <li><a href="/AboutUsPage">О нас</a></li>
            <li><a href="/ContactsPage">Контакты</a></li>
          <div className="social-links">
            <a href="https://t.me/SingleMeow" target="_blank" rel="noopener noreferrer">
              <img src="svg/Telegram.svg" alt="Telegram" />
            </a>
            <a href="https://wa.me/79507946425" target="_blank" rel="noopener noreferrer">
              <img src="svg/whatsapp.svg" alt="WhatsApp" />
            </a>
          </div>
          </ul>
        <button className="header_menu-button" onClick={() => setOpen(!isOpen)}>
          <MenuIcon />
        </button>
      </nav>
    </header>
  );
}

export default Header;