// CardProduct.js
import React, { useState } from 'react';
import '../Components_Home/new.css'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '../buttom';
import Bodyyy from '../Fonts/textBody';
import Bodyyy2 from '../Fonts/textBodyH';
import {ModalNew} from '../Components_Home/ModalNew.js';
import Grid from '@mui/material/Grid';
import './Product.css';

export default function CardProduct({ title, image, volume, price, description, type, package_number, package_type, package_size, package_volume, addToCart }) {
  const [ModalInfoIsOpen, setModalInfoOpen] = useState(false);

  const addElementInCart = () => {
    addToCart({ title, image, volume, price, description, type, package_number, package_type, package_size, package_volume });
    setModalInfoOpen(false);
  };

  return (
    <div className='Card-per-product'>
        <Card 
        sx={{ width: '100%', height: '100%', borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'}}
        onClick={() => setModalInfoOpen(true)}
        style={{ cursor: 'pointer' }}
        >
            <div className='Content-per-product'>
              <div>
                <div style={{width:'200px', height:'260px', alignContent:'center', paddingTop:'10px'}}>
                  <CardMedia
                    component="img"
                    image={`media/${image}`}
                    className="media-query-style"
                  />
                </div>
              </div>
                <CardContent style={{height:'100%', padding:'0', width:'100%'}}>
                  <div className='Text-body-per-product'>
                    <Bodyyy2 text={title}/>
                    <div style={{marginBottom:'10px'}}>
                      <Bodyyy text={`Объем: ${volume}`}/>
                      <Bodyyy text={`Стоимость: ${price} руб`}/>
                    </div>
                  </div>
                </CardContent>
            </div>
            <div className='ButAdd-per-product'>
            <Button onClick={(e) => { e.stopPropagation(); addElementInCart(); }}>Добавить</Button>
                {/* <Button onClick={() => setModalInfoOpen(true)}>Добавить</Button> */}
            </div>
        </Card>
      <ModalNew isOpen={ModalInfoIsOpen} onClose={() => setModalInfoOpen(false)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CardMedia
              component="img"
              width='auto'
              image={`media/${image}`}
              sx={{ borderRadius: '10px' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              <Bodyyy2 text={title} />
              <Bodyyy text={`Объем: ${volume}`}/>
              <Bodyyy text={`Стоимость: ${price} руб`}/>
              <Bodyyy text={`Тип упаковки: ${package_type}`}/>
              <Bodyyy text={`Количество товара в одной упаковке: ${package_number}`}/>
              <Bodyyy text={`Размер коллективной упаковки: ${package_size} см`}/>
              <div className="descript" style={{marginTop:'20px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal'}}>
                <div style={{marginBottom:'80px'}}>
                <Bodyyy text={`Описание: ${description}`} withParagraph={false}/>
                </div>
                <div style={{position: 'absolute', bottom: '20px', right: '20px'}}>
                  <Button onClick={addElementInCart}>Добавить</Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </ModalNew>
    </div>
  );
}
