import React from "react";
import Bodyyy from "../Fonts/textBody";
import TextH11 from "../Fonts/H11";

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
</style>

const CardAboutUS = () => {
  const CardData = [
    {title: 'Собственное производство', content:'Весь посевной материал берется из собственных семенных фондов, все продаваемые культуры выращиваем сами в Омской области. Мы контролируем все этапы производства: от посева до фасовки и отжима масел, что помогает держать цены выгодными, а качество высоким.'},
    {title: 'Хранение на складе зарезервированных для Вас объемов', content:'Мы понимаем сложности хранения сельско-хозяйственной продукции и поможем Вам с отладкой бесперебойной поставки нужного объема в срок.'},
    {title: 'Возможность дополнительной очистки', content:'Если Вам необходимы характеристики партии выше чем мы предложили, за дополнительную плату можем подработать семена. В нашем арсенале широкий набор средств для очистки.'},
    {title: 'Фасовка в любую тару', content:'Обычно мы фасуем семена в мешки, но возможна отгрузка насыпью, в биг-бэгах или в пищевой БОПП-пленке.'},
  ];

  return (
    <div className="CardAboutUSContainer">
      <TextH11 text="О нас"/>
      <div className="CardAboutUSContent">
        <div className="CardAboutUSText">
          {CardData.map((item, index) => (
            <AboutUS key={index} title={item.title} content={item.content} />
          ))}
        </div>
        <div className="CardAboutUSImage">
          <img src="img/kolosok.png" alt="Картинка" style={{ width: '320px', height: 'auto'}}/>
        </div>
      </div>
      
    </div>
  );
};

const AboutUS = ({title, content}) => {
  return (
    <div className="CardAboutUS"> {/* Используем класс из файла стилей */}
      <p style={{fontFamily: 'Merri weather', fontSize: '22px', color:"#50651D", marginBottom:"10px"}}>{title}</p>
      <Bodyyy text={content} withParagraph={false}/>
    </div>
  );
};



export default CardAboutUS;