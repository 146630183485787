<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
</style>

const TextH1 = ({ text }) => {
  return (
    <div style={{fontFamily: 'Merri weather', fontSize: '22px', color:"#50651D", textAlign:'center', marginBottom:"0px"}}>
      <p>{text}</p>
    </div>
  );
};

export default TextH1;

