import React from 'react';


const Bodyyy2 = ({ text }) => {
  return (
    <div className="bodyyy2"> {/* Добавляем класс из файла стилей */}
      <p>{text}</p>
    </div>
  );
};

export default Bodyyy2;