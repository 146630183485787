import React from 'react';
import './Product.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const AddButton = ({ onClick, children }) => {

  return (
    <button className="add_button" onClick={onClick}>
        <AddCircleIcon/>
      {children}
    </button>
  );
};

export default AddButton;