import React from "react";
import Bodyyy from "../Fonts/textBody";
import TextH11 from "../Fonts/H11";
import TextH1 from "../Fonts/H1";
import '../Components_Contacts/Contacts_styles.css';

function CompanyAddress() {
    return (
      <section className="ContainerGeo" style={{width:'80%', margin:'80px auto 0px', textAlign:'center'}}>
        <div className="ContainerGeoText" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom:'40px'}}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5544c4ba9ad97fee5e05dcee8145672c8cacdf12352fea4c8668d16b5bd64658?apiKey=e49b7d88eb524bfa990aed2fcde09947&"
            alt="геометка"
            className="self-center w-16 aspect-square"
          />
          <TextH1 text="Основной офис"/>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop:'-40px'}}>
            <Bodyyy text="646700, Россия, Омская область, Шербакульский район, рп. Шербакуль, ул. Совестская, д. 30"/>
        </div>

      </section>
    );
  }

  export default CompanyAddress;