import React from 'react';
import './Product.css';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const RemoveButton = ({ onClick, children }) => {

  return (
    <button className="remove_button" onClick={onClick}>
        <RemoveCircleIcon/>
      {children}
    </button>
  );
};

export default RemoveButton;