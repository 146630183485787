import * as React from "react";
import TextH1 from "../Fonts/H1";
import Bodyyy from '../Fonts/textBody';

const WhyWe = () => {
    return (
        // <div className="container_why_we">
        //     <TextH11 text="Почему выбирают нас?"/>
            <div className="card">
                <IconWithDescription
                    iconUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/8fe167342902653a842b8dd70868aff89537759b148d1ce6187d1cb505782c8b?"
                    title="Качество"
                    description="Гарантируем обмен товара, если качество не соответствовало заявленным характеристикам"
                />
                <IconWithDescription
                    iconUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/f8ca508171d1aaa23f0110b8c4c46b3aa01e10ddc375c088a1d64d87daad859c?"
                    title="Солидарность"
                    description="Любому бизнесу нужна поддержка. Гибкие условия хранения и резервирования продукции"
                />
                <IconWithDescription
                    iconUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/a9fc09043bc3aec2b8123699828f8c00bef459d0f3d17e90679c3d77d72c50f5?"
                    title="Доставка"
                    description="Отправим товар любой транспортной компанией в нужный Вам регион"
                />
                <IconWithDescription
                    iconUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/564c7efda96c1bbd90cfb277038fc71df16d298d297a6c65bab9ae5f33b25fea?"
                    title="Партнерство"
                    description="Расскажем про Вас в рекламе нашего товара и дадим возможность представлять наш товар в Вашем городе"
                />
            </div>
        // </div>
    );
}

function IconWithDescription({ iconUrl, title, description }) {
    return (
        <div className="cards">
            <img
                loading="lazy"
                src={iconUrl}
                className="image"
                alt="Иконочка"
            />
            <TextH1 text={title}>{title}</TextH1>
            <Bodyyy text={description} className="description" withParagraph={false}></Bodyyy>
        </div>
    );
}

export default WhyWe;