import React, { useState } from 'react';
import '../Components_Home/new.css'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
import {CardActionArea, CardActions } from '@mui/material';
import Button2 from '../buttom2';
import Bodyyy from '../Fonts/textBody';
import Bodyyy2 from '../Fonts/textBodyH';
import {ModalNew} from '../Components_Home/ModalNew.js';
import Grid from '@mui/material/Grid';
<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
</style>



const handleClick = () => {
  console.log('Button clicked!');
};

export default function NewCard({ title, description, date, image }) {
  const [ModalInfoIsOpen, setModalInfoOpen] = useState(false);
  return (
    <div className="NewCard">
      <Card sx={{ width: '100%', height: '100%', borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'}}>
        <div >
          <CardMedia
            className='PhotoNew'
            component="img"
            // height="200"
            image={`media/${image}`}
          />
          <div className='TextNew'>
          {/* <CardContent> */}
          <div>
            <Bodyyy text={date} withParagraph={false}/>
            <Bodyyy2 text={title} />
          </div>
            <div className="descriptionnn" style={{marginTop:'-10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal'}}>
              <Bodyyy text={description} withParagraph={true}/>
            </div>
          {/* </CardContent> */}
          </div>
        </div>
        <div style={{ position: 'absolute', bottom: '20px', left: '20px', width: '100%', textAlign: 'left', cursor: 'pointer' }}>
          <Button2 onClick={() => setModalInfoOpen(true)}>Подробнее...</Button2>
        </div>
      </Card>
      <ModalNew isOpen={ModalInfoIsOpen} onClose={() => setModalInfoOpen(false)}>
        <Grid container spacing={2} className="grid-container">
          <Grid item xs={12} sm={6} className="grid-item">
            <CardMedia
              component="img"
              height="auto"
              image={`media/${image}`}
              sx={{ borderRadius: '10px' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grid-item">
            <div>
              <Bodyyy text={date}/>
              <Bodyyy2 text={title} />
              <div className="descript" style={{marginTop:'-20px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal'}}>
                <Bodyyy text={description} withParagraph={true}/>
              </div>
            </div>
          </Grid>
        </Grid>
      </ModalNew>
    </div>
  );
}