import * as React from "react";
import TextH11 from "../Fonts/H11";
import TextH1 from "../Fonts/H1";
import Bodyyy from "../Fonts/textBody";

const FourContacts = () => {
    return (
        <div className="container_why_we" style={{width:'100%'}}>
            <div className="card">
                <IconWithDescription
                    iconUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/9af38fde6b526d9b9351b0dc76a9394a178894b16cabb447b33162298ceeaadf?apiKey=e49b7d88eb524bfa990aed2fcde09947&"
                    title="Режим работы"
                    description= {
                        <>
                            Будние дни: 9:00 — 19:00 <br />
                            Суббота и Воскресенье: 10:00-18:00
                        </>
                    }
                />
                <IconWithDescription
                    iconUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/697ef14a9c4c7535160fecb439f080520a6e990884b1adfa9995905f1a1da1ad?apiKey=e49b7d88eb524bfa990aed2fcde09947&"
                    title="Телефон"
                    description="+7-950-794-64-25"
                />
                <IconWithDescription
                    iconUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/16ace0730f8ac9f9f09e6aa5b42b8acdd956866a723fd7ad93a740b9a1a32212?apiKey=e49b7d88eb524bfa990aed2fcde09947&"
                    title="Электронная почта"
                    description={
                        <>
                            shilov@shikoil.ru <br />
                            shilovvladimirs@gmail.com
                        </>
                    }
                />
                <IconWithDescription
                    iconUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/a85e044a71a12e691e8da561973da55697de83fa6fed45994df917eb31b109bc?apiKey=e49b7d88eb524bfa990aed2fcde09947&"
                    title="Реквизиты"
                    description={
                        <>
                            ИП Глава КФХ Шилов Сергей Николаевич <br />
                            ИНН: 554000229700 <br />
                            ОГРНИП: 315554300022282​
                        </>
                    }
                />
            </div>
        </div>
    );
}

function IconWithDescription({ iconUrl, title, description }) {
    return (
        <div className="cards">
            <img
                loading="lazy"
                src={iconUrl}
                className="image"
                alt="Иконочка"
            />
            <TextH1 text={title}>{title}</TextH1>
            <Bodyyy text={description} className="description" withParagraph={false}></Bodyyy>
        </div>
    );
}

export default FourContacts;