
import React, { useState, useRef } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '../buttom';
import {ModalNew} from './ModalNew.js';
import Grid from '@mui/material/Grid';
import TextH11 from "../Fonts/H11.js";
import Bodyyy from '../Fonts/textBody';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CircularIndeterminate from './loading.js';

export default function FeedbackForm() {
  const [emailIsSending, setEmailIsSending] = useState(false);
  // let emailIsSending = false
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [ModalInfoIsOpen, setModalInfoOpen] = useState(false);
  const [error, setError] = useState(false);
  // let feedbackError = false;

  // const [error, setError] = useState('');
  const handleClick = () => {
    if (name === '' || email === '') {
      setError(true);
      return;
    }
    else {
      setError('');
      setEmailIsSending(true);
      
      
      // this.emailIsSending = true

      fetch(
        '/send_feedback',
        {
          method: 'POST',
          body: JSON.stringify(
            {
              email: email,
              name: name,
              message: message
            }
          ),
          headers: {
            'content-type': 'application/json'
          }
        }
      )
        .then(response => {
          if (response.status == 200) {
            setModalInfoOpen(true);
            setName('');
            setEmail('');
            setMessage('');
          } else {
            console.log('SERVER ERROR')
          }
        })
        .catch(error => {
          console.log('ERRRRROR')
        })
        .finally(() => {
          setEmailIsSending(false);
        });

    }
  };



  return (
    
    <div className='myFormPhoto'> 
      <div className='myForm'> 
        <Stack
          component="form"
          sx={{
            width: '100%',
            '& .MuiFilledInput-underline:hover:before': {
              borderBottomColor: 'transparent', // Убираем подсветку при наведении
            },
            '& .MuiFilledInput-underline:after': {
              borderBottomColor: 'transparent', // Убираем подсветку после нажатия
            },
            '& .MuiInputLabel-root': {
              color: 'inherit', // Убираем подсветку текста метки
            },
            '& .MuiInputLabel-root': {
              color: '#6D808C', // Устанавливаем цвет текста метки
              fontSize: '14px', // Устанавливаем размер текста метки
            },
            '& .MuiInputBase-root': {
              color: 'black', // Устанавливаем цвет текста ввода
              fontSize: '14px', // Устанавливаем размер текста ввода
            },
            '& .MuiFilledInput-root': {
              borderTopLeftRadius: '20px', // Закругление верхнего левого угла
              borderTopRightRadius: '20px', // Закругление верхнего правого угла
            },
            '& .MuiInputLabel-shrink': {
              color: '#6D808C', // Устанавливаем цвет текста метки после фокуса
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#50651D', // Устанавливаем цвет текста метки при фокусе
            },
            '& .MuiFilledInput-underline:after': {
              borderBottomColor: '#50651D', // Устанавливаем цвет подсветки при нажатии
            },
          }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="ФИО"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="filled"
            required
            error={error}
            helperText={error ? 'Поле обязательно для заполнения' : ''}
              
          />
          <TextField
            label="Email/Номер телефона"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="filled"
            required
            error={error}
            helperText={error ? 'Поле обязательно для заполнения' : ''}
          />
          <TextField
            label="Ваш вопрос"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="filled"
            multiline  // Устанавливаем многострочное поле ввода
            rows={5}   // Устанавливаем количество строк
            sx={{ height: '160px' }} 
          />
        </Stack>
        <div className='ButtonMyForm'>
          <Button onClick={handleClick}>Отправить</Button>
        </div>
      </div>
      <div className='PicMyForm'>
        <img src="/img/feedback.jpeg" alt="" style={{ width: '100%', height: 'auto'}} />
      </div>

      <ModalNew isOpen={ModalInfoIsOpen} onClose={() => setModalInfoOpen(false)}>
        <Grid alignItems="center" justifyContent="center">
          <Grid style={{ textAlign: 'center' }}>
            <div style={{ display: 'inline-block' }}>
              <CardMedia
                component="img"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/af4bd0ba931515d81d64ccdeb288b8b15e8feccda4874dc9cd32e1c9a38e58c9?apiKey=e49b7d88eb524bfa990aed2fcde09947&"
              />
            </div>
            <div className='ModalTextFeedback'>
              <TextH11 text="Спасибо за обратную связь!"/>
              <div className="descript" style={{marginTop:'-20px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal'}}>
                <Bodyyy text="В скором времени мы с вами свяжемся"/>
              </div>
            </div>
          </Grid>
        </Grid>
      </ModalNew>

      {emailIsSending && <CircularIndeterminate />} {/* Значок загрузки будет отображаться, если emailIsSending равно true */}
    </div>
  );
}