import React from 'react';
import HomePage from './HomePage'; 
import './styles.css'; 
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AboutUsPage from './Components_AboutUS/AboutUsPage'; 
import Header from './Components_Home/Header';
import ContactsPage from './ContactsPage';
import ServicesPage from './Components_ServicesPage/ServicesPage';
import Product from './Components_Products/Product';
import NotFound from './NotFound';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/AboutUsPage" element={<AboutUsPage />} />
          <Route path="/ContactsPage" element={<ContactsPage/>}/>
          <Route path="/ServicesPage" element={<ServicesPage/>}/>
          <Route path="/Product" element = {<Product/>}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    
    </Router>
  );
}

export default App;