import React from 'react';
import Bodyyy from "../Fonts/textBody.js";
import Bodyyy2 from "../Fonts/textBodyH.js";
import './stylesAboutUsPage.css'; 
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


const AboutUsHistory = () => {
    return (
        <div className="container_history">
            {/* <div className="card_history"> */}
                <IconWithDescription2
                    icon="../svg/aboutus1.svg"
                    title="Основание компании:"
                    description="КФХ ''Богатство Шербакуля'' (зарегистрированная торговая марка компании) было основано в 1992 году в Шербакульском районе Омской области."
                />
                <IconWithDescription2
                    icon="../svg/aboutus2.svg"
                    title="Первые десятилетия деятельности:"
                    description="До 2010 года компания занималась выращиванием зерновых культур для последующей продажи. Также имелось небольшое поголовье сельскохозяйственных животных."
                />
                <IconWithDescription2
                    icon="../svg/aboutus3.svg"
                    title="Специализация на масличных культурах:"
                    description="С 2010 года компания специализируется на выращивании и переработке масличных культур. Запущен маслобойный цех, начаты продажи растительных масел под маркой ''ШиК''."
                />
                <IconWithDescription2
                    icon="../svg/aboutus4.svg"
                    title="Расширение ассортимента в 2020 году:"
                    description="В 2020 году был запущен фасовочный цех, и ассортимент компании расширился за счёт новых видов продукции: фасованных семян льна, расторопши, горчицы высокой очистки (food grade)."
                />
            {/* </div> */}
        </div>
    );
  }

  function IconWithDescription2({ icon, title, description }) {
    return (
        <div className="cards_history_aboutus">
            <img
                src={icon}
                className="image"
                alt="Иконочка"
            />
            <div className='HistoryText1'>
            <Bodyyy2 text={title}>{title}</Bodyyy2>
            <Bodyyy text={description} className="description" withParagraph={false}></Bodyyy>
            </div>
        </div>
    );
  }

  export default AboutUsHistory;