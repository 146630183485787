import React from 'react';
import './buttom.css';

const Button2 = ({ onClick, children }) => {

  return (
    <button2 className="button2" onClick={onClick}>
      {children}
    </button2>
  );
};

export default Button2;