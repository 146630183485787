import React, { useState, useEffect } from 'react';
import NewCard from './new.js'

export default function News() {
	const [news, setNews] = useState([]);

	useEffect(() => {
			const fetchData = async () => {
					try {
							const response = await fetch('/news');
							const data = await response.json();
							setNews(data);
					} catch (error) {
							console.error('Error fetching news:', error);
					}
			};

			fetchData();
	}, []);

	return (
			<div style={{ padding: '0 10%', marginBottom: '20px' }}>
					<div style={{ overflowX: 'auto', whiteSpace: 'nowrap', borderRadius: '10px', padding: '10px', scrollbarWidth: 'auto', scrollbarColor: '#98A446 #EBE8E3', '::-webkit-scrollbar': { width: '8px' }, '::-webkit-scrollbar-thumb': { borderRadius: '10px', backgroundColor: '#98A446' } }}>
							<div style={{ padding: '10px' }}> {/* Внутренние отступы */}
									{news.map((item, index) => (
											<div key={index} style={{ display: 'inline-block', marginRight: '20px' }}>
													<NewCard title={item.title} description={item.description} date={item.date} image={item.image} />
											</div>
									))}
							</div>
					</div>
			</div>
	);
}