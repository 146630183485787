
import React from 'react';
import '../styles.css';
import { WrapText } from '@mui/icons-material';


const Bodyyy = ({ text, withParagraph }) => {
  const textStyle = {
    fontFamily: 'Montserrat',
    fontWeight: 320,
    fontSize: '15px',
    textIndent: '20px'
  };

  return (
    <div className="bodyyy">
      {withParagraph ? (
        <p style={textStyle}>{text}</p>
      ) : (
        <span style={textStyle}>{text}</span>
      )}
    </div>
  );
};

export default Bodyyy;
